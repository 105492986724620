
.player-block-inst {
  position: relative;

  .name {
    text-align: center;
    position: absolute;
    width: 93%;
    height: 14.1vw;
    line-height: 2;
    font-size: 6.9vw;
    right: 10px;
    bottom: 0;
    color: #101421;
    font-weight: bold;
    background-color: #1b1b26;
    text-shadow: 1px 0 0 #919fac, -1px 0 0 #919fac, 0 1px 0 #919fac, 0 -1px 0 #919fac, 1px 1px #919fac, -1px -1px 0 #919fac, 1px -1px 0 #919fac, -1px 1px 0 #919fac;

  }


}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
  position: absolute;
  bottom: 0;
  border-top: 2px solid #FF0D98;
  background-color: rgba(33, 37, 50, 0.77);

  .random-button, .play-button {
    margin-left: 25px;
    margin-right: 25px;
  }
}
