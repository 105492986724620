@import "../../../../styles/variables";

$itemHeight: 40px;

.history-bet-item-container {

  .history-bet-item {
    display: flex;
    margin: 10px auto auto;
    height: auto;
    min-height: 110px;
    width: 95vw;
    border-radius: 5px;
    background-color: $themeColor4;
    position: relative;

    &:before {
      content: "▼";
      position: absolute;
      font-size: 12px;
      background: unset !important;
      color: #e6e7f5;
      pointer-events: none;
      padding: 11px 0 0;
      right: 48vw;
      line-height: 15px
    }

    &.opened:before {
      content: "▼";
      position: absolute;
      font-size: 12px;
      background: unset !important;
      color: #e6e7f5;
      pointer-events: none;
      padding: 3px 0;
      right: 48vw;
      line-height: 30px;
      transform: rotate(180deg);
    }
  }

  .bet-info-item {
    width: 100%;
    line-height: 40px;

    .date-and-time {
      position: absolute;
      right: 25px;
      padding-top: 28px;
    }

    &.bet-type {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 4px 2px 2px;
        user-select: text;

        .date-and-time {
          position: unset;
          right: 3px;
        }

        p {
          line-height: 18px;
          padding: 0 6px;
          margin: 2px;

          &.id {
            margin: 0;
          }

          &.bet-result-info {
            position: unset;
            right: 3px;

          }
        }

      }
    }

    p {
      float: left;
      line-height: 2px;

      &.id {
        margin-left: 10px;
        margin-top: 0;
      }
    }

    .bet-result-info {
      color: #ff2c2c;
      position: absolute;
      right: 25px;
      padding-top: 6px;

      &.win {
        color: #8fd414;
      }

      &.lost {
        color: #ff2c2c;
      }

      &.return, &.accepted {
        color: #dddddd;
      }

      &.cashouted {
        color: #ffdb59;
      }
    }

    .win-and-coef-info {
      display: flex;
      justify-content: space-between;
      padding-left: 2px;
      padding-right: 2px;

      p, .bet-stake {
        position: unset;
        padding: 0 5px;
      }
    }

    .odd-info {
      display: flex;
      justify-content: space-between;

      .coef-value {
        position: unset;
        padding: 0 8px;
      }

      p {
        margin-left: 8px;
        margin-top: 12px;
        color: #9295a1;

        &.win {
          color: #8fd414;
        }
      }
    }

    .game-info {
      display: flex;
      flex-direction: column;
      margin-bottom: 4px;
      padding: 0 8px;

      .betItem {
        color: #9295a1;

        &.title {
          color: #fdfdfd;
          font-weight: bold;
          font-size: 12px;
        }
      }

      .betItem-multi {
        display: flex;
        justify-content: space-between;
      }
    }

    &.result {
      flex-direction: row;
      background-color: #404453;
      margin-top: 2px;

      .game-info:last-child {
        text-align: right;
        border-bottom: 1px solid $asset2;
        height: 33px;
        margin-right: 8px;
      }
    }
  }

  .data-id {
    display: flex;
    //max-height: $itemHeight;
    flex-direction: column;
    text-align: left;
  }

  .status-icon {
    flex: 2;
    line-height: $itemHeight;
    background-color: #404453;

    p {
      width: 18px;
      height: 18px;
      margin-top: 8px;
      line-height: 18px;
      border: 2px solid #9c9cac;
      border-radius: 18px;
    }
  }

  .bet-type {
    p {
      line-height: 13px;
      margin: 10px 0
    }

    .bet-type-name {
      float: left;
      margin-left: 10px;
    }
  }

  .bet-stake, .odd-type, .odds, .outcome {
    position: absolute;
    right: 25px;
    line-height: 33px;
  }

  .coef-value {
    position: absolute;
    line-height: 30px;
    right: 30px;
    color: #ba8237;

    &.lost {
      color: #ff1d1d
    }
  }

  .win {
    color: #8fd414;
  }

  .lost {
    color: #ff1d1d;
  }
}
