@import "../../styles/variables";

:root {
  --bets-counter-position: 17vh
}

$borderRadius: 14px;
$iconsSize: 17px;
$mainBackgroundColor: rgba(0, 0, 0, 0.5);
$backgroundColor: $themeColor2;
$textColor: #e6e7f5;

.viewport-container {
  display: flex;
  flex-direction: column;
  background-color: $mainBackgroundColor;
  margin-bottom: 45px;

  .viewport-header {
    display: flex;
    position: fixed;
    height: 50px;
    width: 100vw;
    line-height: 50px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: $themeColor4;
    z-index: 100;

    .custom-select select:after {
      background: $themeColor4;
      position: relative;
    }

    .custom-select:after {
      content: "▼";
      position: absolute;
      right: 24%;
      font-size: 69%;
      background: unset !important;
      color: $textColor;
      pointer-events: none;
      line-height: 30px
    }

    .back-to-sports-block {
      width: 50px;
      display: flex;
      justify-content: center;
      border-right: 2px solid $themeColor3;
      cursor: pointer;
      line-height: 50px;
      font-size: 24px;
    }

    .custom-select {
      display: contents;
      width: auto;
      border-radius: 0;
      background-color: transparent;
      align-items: center;
      z-index: 10;
      padding: 0 20px 0 0;
      flex-basis: 50%;
      min-width: 120px;
      justify-content: unset;

      select {
        background-color: $themeColor4;
        height: 50px;
        text-indent: 6px;
        padding: 1px 0 1px 7px;
        line-height: 12px;
        overflow: hidden !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        width: 80% !important;
        resize: horizontal;
        line-height: 20px;
      }
    }

    .history-icon, .results-icon {
      display: flex;
      width: 50px;
      height: 100%;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      border-left: 2px solid $themeColor3;
      flex-basis: 60px;

      &:active {
        opacity: 0.5;
      }
    }

    .history-icon img {
      height: 43%;
    }

    .results-icon img {
      height: 45%;
    }
  }

  .viewport-content {
    background-color: $themeColor0;
    margin-top: 50px;

    .live-table-title {
      display: flex;
      align-items: center;
      height: 40px;
      line-height: 31px;
      text-align: left;
      font-weight: bold;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background-color: #161C28;
      background: -moz-linear-gradient(top, #121926 0%, #1e2430 100%);
      background: -webkit-linear-gradient(top, #121926 0%, #1e2430 100%);
      background: linear-gradient(to bottom, #121926 0%, #1e2430 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#121926', endColorstr='#1e2430', GradientType=0);
      border-bottom: 1px solid #121926;
      padding: 0 16px 0;
      font-size: 11px;

      .live-btn {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 9px;
      }

      .close-bet-slip {
        display: flex;
        float: right;
        line-height: 45px;
        margin-right: 6px;
        font-size: 12px;
      }

      .live-icon {
        width: 8px;
        height: 8px;
        float: left;
        margin-right: 8px;
        border-radius: 50%;
        background: rgba(97, 239, 97, 0.24);
        box-shadow: 0 0 0 rgba(97, 239, 97, 0.24);
        animation: pulse 2s infinite;
      }
    }
  }

  .video-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    min-height: 120px;

    .video-item {
      display: flex;
      width: 100%;
      overflow: hidden;
      position: relative;

      .video-controls {
        width: 100vw;
        height: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.18);;
        bottom: 0;
        opacity: 0;
        transition: all 0.4s linear;
        z-index: 99;

        &.hide {
          opacity: 0;
        }

        .items {
          margin-right: 14px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .video-volume-slider {
            width: 85px;
            height: 3px;
            border-radius: 5px;
            background: #d3d3d3;
            outline: none;
            opacity: 0.7;
            transition: opacity .2s;
            margin-top: 0;
            margin-right: 6px;
            -webkit-appearance: none;
          }

          .control-icon, .control-play-pause-icon {
            width: $iconsSize;
            height: $iconsSize;
            margin-top: 0;
            margin-right: 7px;
            margin-left: 5px;
            float: right;
            cursor: pointer;
          }

          .control-play-pause-icon {
            margin-right: 100%;
          }
        }
      }

      .tennis-info {
        width: 92%;
        height: auto;
        margin: 0 auto;
        position: absolute;
        top: 25%;
        left: 3%;
        z-index: 10;
        display: block !important;

        &.final {
          top: 16%;

          .final-info {
            display: flex;
            width: 100%;
            height: auto;
            margin: 0 auto;
            position: absolute;
            top: 25%;
            left: 3%;
            z-index: 10;
            justify-content: center;
            flex-direction: column;

            .name-block {
              display: flex;
              width: 100%;
              height: 30%;
              justify-content: center;
              position: relative;
              margin-top: 35%;
              font-size: 0.8vw;
              font-weight: bold;
              white-space: pre;
              text-shadow: 2px 2px #393939;
              padding: 0 6px;
              background-color: rgba(97, 103, 123, 0.6);
              -webkit-box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.85);
              -moz-box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.85);
              box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.85);

              &.left {
                float: left;
              }

              &.right {
                float: right;
              }

              p {
                margin: auto 2px;
              }

              .flag {
                margin: 5%;
              }

              .win-icon {
                position: absolute;
                width: 22%;
                top: -10px;

                &.l {
                  left: -12px;
                }

                &.r {
                  right: -17px;
                }
              }
            }

            .match-result {
              width: 47%;
              height: 86%;
              font-weight: bold;
              margin: 0 auto;

              .container {
                width: 100%;
                margin-top: 0;
                height: 126%;
                background-color: rgba(97, 103, 123, 0.6);
                -webkit-box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.85);
                -moz-box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.85);
                box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.85);

                .header {
                  height: 33%;
                  font-size: 0.8vw;
                  background-color: #0A1320;
                  display: flex;
                  justify-content: flex-end;

                  .list {
                    display: flex;
                    width: 50%;
                    justify-content: space-around;
                    text-align: center;

                    .final-column {
                      background-color: rgba(216, 24, 24, 0.57);
                      width: 20%;
                    }

                    p {
                      margin: auto;

                      &.minWidth {
                        min-width: 17px;
                      }
                    }
                  }
                }

                .match-result-content {
                  width: 100%;
                  height: 75%;
                  font-size: 0.8vw;

                  .line {
                    width: 100%;
                    height: 1px;
                    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
                    background: #0a1320;
                    margin: 0 auto;
                  }

                  .scores-block {
                    display: flex;
                    width: 100%;
                    height: auto;
                    text-shadow: 2px 2px #181818;
                    line-height: 28px;
                    align-items: center;

                    .flag {
                      width: 4.2vw;
                      margin: auto;
                    }

                    .player-name {
                      margin: auto;
                      white-space: pre;
                      line-height: 0;
                    }

                    .scores-list {
                      display: flex;
                      width: 50%;
                      justify-content: space-around;
                      text-align: center;

                      .final-column {
                        background-color: $asset3;
                        width: 20%;
                      }

                      p {
                        margin: auto;
                      }
                    }
                  }
                }
              }
            }

            .players {
              display: flex;
              justify-content: space-around;

              .player1-info, .player2-info {
                width: 43%;
                position: relative;
                display: flex;
                justify-content: center;

                .player-img {
                  width: 45%;
                  position: absolute;
                  top: -5.5%;

                }
              }
            }

            .player2-info {
              .player-img {
                left: 38%;
              }
            }
          }
        }

        .start-info {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;

          .players-block {
            display: flex;
            position: relative;
            justify-content: space-between;
            width: 100%;
            height: 60%;
            margin-bottom: 10px;
            min-height: 124px;

            .p-block {
              width: 34%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              &.right object {
                border-left: 25px solid transparent;
                border-right: 0;
              }

              .p-name {
                display: flex;
                position: absolute;
                justify-content: center;
                width: auto;
                height: 28px;
                font-size: 13px;
                bottom: 0;
                font-weight: bold;
                text-shadow: 1px 1px #262626;
                padding: 0 12px;

                &.left {
                  background: rgba(0, 87, 50, 0.7);
                }

                &.right {
                  background: rgba(0, 44, 107, 0.7);
                  right: 0;
                }

                p {
                  line-height: 3px;
                }
              }

              img {
                width: 100%;
                display: block;
              }
            }
          }

          .game-info {
            display: flex;
            width: 100%;
            height: 53%;
            pointer-events: none;
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.7);
            -moz-box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.7);
            box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.7);
            text-shadow: 1px 1px #262626;
            margin-bottom: 3px;
            font-size: 13px;

            .p-info {
              width: 30%;
              height: 100%;

              p {
                margin: auto;
              }

              &.p1 {
                margin-left: 10%;
              }

              &.p2 {
                margin-right: 10%;
              }

              .p-number {
                display: flex;
                height: 22px;
                text-align: center;
                padding-bottom: 3px;
                justify-content: center;

                &.left {
                  background: rgb(0, 138, 97);
                  background: linear-gradient(180deg, rgba(0, 138, 97, 1) 0%, rgba(0, 63, 41, 1) 100%);
                }

                &.right {
                  background: rgb(8, 78, 133);
                  background: linear-gradient(180deg, rgba(8, 78, 133, 1) 0%, rgba(0, 49, 90, 1) 100%);
                }
              }

              .p-info-list {
                height: 80%;

                p {
                  height: 20px;
                  margin-top: 5%;
                  margin-bottom: 5%;
                }
              }
            }

            .match-info {
              width: 40%;
              height: 100%;
              margin-top: 5%;

              p {
                margin-top: 1px;
                margin-bottom: 5%;
              }
            }
          }
        }

        .process-info {
          display: flex;
          width: 100%;
          height: 100%;
          position: relative;

          .scores-info {
            position: absolute;
            top: 5%;
            left: 2%;
            background-color: #000000;
            border-radius: 5px;
            border: 2px solid #8098a0;
            font-size: 0.7vw;
            font-weight: bold;

            .players-scores {
              width: 100%;
              display: flex;
              align-items: center;

              &.p1 {
                border-bottom: 2px solid #8098a0;
              }

              .name {
                width: 60%;
                white-space: pre;
                text-align: left;
                padding-left: 5px;
                padding-top: 2px;
              }

              .ball-icon {
                width: 9px;
                height: 9px;
                border-radius: 50%;
                margin-left: 8px;
                background: rgb(230, 218, 0);
                background: linear-gradient(180deg, rgba(230, 218, 0, 1) 0%, rgb(127, 138, 53) 100%, rgba(2, 0, 36, 1) 000326%);
              }
            }

            .game-score-info {
              display: flex;
              margin-left: 7px;

              .score {
                padding-left: 7px;
                padding-right: 7px;
                margin-bottom: 2px;
                border-radius: 5px;
                border: 2px solid #8098a0;
                margin-top: 2px;
                margin-right: 2px;

                &.set-score {
                  background-color: #00794b;
                }

                &.match-score {
                  background-color: #7700A8;
                }
              }

              .game-score {
                width: 15px;
              }
            }
          }
        }
      }
    }
  }

  .game-title {
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    background: $themeColor2;
  }

  .bets-counter {
    width: 40px;
    height: 40px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: var(--bets-counter-position);
    right: 0;
    font-size: 12px;
    cursor: pointer;
    visibility: visible;
    transition: all 0.2s linear;
    z-index: 100;
    //background: var(--vs-asset1);
    //background: radial-gradient(circle, var(--vs-asset1) 50%, rgb(133, 0, 73) 100%);

    &.animate {
      animation-name: animateBetSlip;
      animation-duration: 0.3s;
    }

    @keyframes animateBetSlip {
      0% {
        width: 50px;
        height: 50px;
      }
      100% {
        width: 40px;
        height: 40px
      }
    }

    .images {
      position: relative;
      background-color: $betSlipPopUpBg;
      padding: 15px;
      border-radius: 180px;

      .betSlipIcon {
        width: 25px;
        height: 25px;
      }
    }

    p {
      width: 22px;
      height: 22px;
      background: #ebe54f;
      color: #6b1945;
      border-radius: 20px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -8%;
      bottom: 19px;
    }
  }

  &.long {
    margin-bottom: 365px;
  }
}