@import "../../../styles/variables";

.large-market {
  width: 100%;

  &.disable {
    .large-market-container {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .cash-out-icon {
    width: 11px;
    height: 11px;
    line-height: 11px;
    font-size: 8px;
    color: #1e8559;
    margin-right: 6px;
    border-radius: 20px;
    border: 2px solid #1e8559;
    margin-top: 8.8px;
    text-align: center;
  }

  .large-market-container {
    width: 100%;
    float: left;
    display: flex;
    margin-bottom: 10px;
    cursor: default;
    flex-direction: column;
  }

  .large-market-element {
    display: flex;
    float: left;
    width: 100%;
    margin-right: 3px;
    flex-direction: column;

    .closed {
      display: none;
    }
  }

  .match-running-info {
    width: 90%;
    margin-left: 5%;
    float: left;
    text-align: center;
    font-size: 13px;
    padding-top: 10px;
    line-height: 21px;
    color: #a7abba;
    font-weight: bold;
  }

  .large-market-element-item {
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    float: left;
    height: 35px;
    line-height: 35px;
    padding-left: 10px;
    font-size: 13px;
    contain: strict;
    cursor: pointer;
    background: $themeColor2;
    border-bottom: 1.5px solid $themeColor2;
  }

  .market-element-options {
    display: flex;
  }

  .start-bookmark {
    font-size: 18px;
    line-height: 33px;
    padding-right: 8px;
    cursor: pointer;
    color: #68686A;

    &.own {
      color: #68686A;
    }

    &.bookmarked {
      color: #c48b32;
    }
  }


  .start-bookmark:active {
    color: #b17925;
  }

  .market-item-open-close-arrow {
    float: right;
    height: 0;
    padding: 3px;
    margin-right: 15px;
    border: solid #6f6e7b;
    border-width: 0 3px 3px 0;
    display: inline-block;

    &.up {
      margin-top: 15px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    &.down {
      margin-top: 12px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  .large-market-element-item-prices {
    display: flex;
    flex-wrap: wrap;

    .large-market-info-item {
      display: flex;
      width: 100%;
      float: left;
      font-size: 13px;
      justify-content: space-between;
      border-left: 1px solid #0d0d13;
      border-bottom: 1px solid #0d0d13;
      background-color: $themeColor4;
      height: 35px;

      p {
        font-size: 12px;
        font-weight: bold;
        line-height: 10px;
      }
    }

    .price:hover {
      background-color: $themeColor5;
      cursor: pointer;
    }

    .active, .active:hover {
      p {
        color: white;
      }

      background-color: $asset1;
    }
  }

  .large-market-info-item-type {
    color: #c5c7ce;
    font-size: 12px;
    font-weight: bold;
    margin-left: 8px;
    padding-left: 5px;
  }

  .large-market-info-item-price {
    margin-right: 8px;
    padding-right: 5px;
    color: #b17925;
  }
}