@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@300;400;700;900&display=swap');
@import "variables";

html, body {
  max-width: 100vw !important;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Noto Sans Armenian", sans-serif !important;
  background-color: $mainBackgroundColor;
  color: white;
  overflow-x: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}