@import "../../../../styles/variables";

$borderColor: #101218;

.game-data-block {
  width: 100%;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  background-color: $themeColor2;
  margin-bottom: 4px;

  .game-date-header {
    display: flex;
    width: 100%;
    height: 80px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $themeColor3;
    color: #d8d8d8;

    .players-info {
      width: 40%;
      display: flex;
      justify-content: space-around;
      border-right: 2px solid $borderColor;
      height: inherit;

      .player-block {
        margin-top: 15px;
        position: relative;
        cursor: pointer;
        text-shadow: 2px 2px #2a2a2a;
        font-size: 15px;

        .flag {
          height: 35px;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }

        .name {
          text-align: left;
          position: relative;
          padding-left: 45px;
          z-index: 2;
        }

        .surname {
          position: relative;
          padding-left: 45px;
          z-index: 2;
        }

        .statistic-icon {
          margin-top: 5px;
          float: right;
        }
      }

      .vs {
        font-size: 42px;
        line-height: 90px;
        font-weight: lighter;
      }
    }

    .score-info {
      width: 100%;
      border-right: 2px solid $borderColor;
      height: inherit;
      font-weight: normal;

      .score-item {
        display: flex;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        height: 40px;

        .player-name {
          width: auto;
          display: flex;
          margin: 0 6px 0 8px;
          justify-content: flex-start;
          min-width: 90px
        }

        .scores {
          display: flex;
          line-height: 40px;
          width: 35%;

          .score {
            flex: 3;
            border-left: 1px solid #595d66;
            padding-left: 1%;
            padding-right: 1%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        img.statistic-icon {
          height: 16px;
          cursor: pointer;
        }

        .player-info-row, .player-item {
          width: 65%;
          display: flex;
          justify-content: start;
          align-items: center;
          padding: 10px;

          .matchScore-ball {
            width: 14px;
            height: 12px;
            margin: 2px 5px 2px 0;
            background-size: 90%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .player-info-row {
          justify-content: space-between;

          .player-item {
            width: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 10px;
            cursor: pointer;

            .player-img-small {
              width: 25px;
              height: 25px;
              margin-right: 9px;
              background-size: 100%;
              background-repeat: no-repeat;
              background-position: center;
              border-radius: 20px;
              border: 1px solid #595d66;
            }

            .player-name {
              width: 50%;
            }
          }
        }
      }
    }
  }

  .next-point-info {
    margin: auto;
    font-size: 17px;
    line-height: 30px;
    white-space: pre;
    font-weight: bold;
    display: flex;
    justify-content: left;
    height: 30px;

    p {
      width: 100%;
      line-height: 10px;
      margin-right: 12px;
    }

    .next-timer {
      font-size: 12px;
      color: #e0e0e0;
      display: flex;
      justify-content: space-between;
    }
  }

}




