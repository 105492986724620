@import "../../../styles/variables";

.live-tables {
  display: block;
  width: 100vw;
  font-size: 11px;
  z-index: 100;
  cursor: default;

  .live-table-tabs {
    display: flex;
    justify-content: start;
    height: 40px;
    line-height: 42px;
    text-align: left;
    font-weight: bold;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: var(--vs-col1);

    .active {
      border-bottom: 2px solid var(--vs-asset1);
      color: white;

      .live-icon {
        width: 8px;
        height: 8px;
        float: left;
        margin-right: 8px;
        border-radius: 50%;
        background: #61EF61;
        box-shadow: 0 0 0 #61ef61;
        animation: pulse 2s infinite;
      }
    }
  }
}

.live-scores, .live-table {
  margin: 5px 0;
  height: inherit;
}

.live-table-title {
  height: 40px;
  line-height: 42px;
  display: flex;
  justify-content: start;
  flex-basis: 50%;
  text-align: left;
  font-weight: bold;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: $themeColor1;
  border-bottom: 2px solid $asset1;
  padding: 0 12px 0 24px;
}

.live-scores-content {
  width: 100%;
  height: 140px;
  display: grid;
  grid-template-columns: 2fr 2fr;
  background-color: $themeColor0;

  .live-score-info {
    display: inline-grid;
    grid-template-columns: 2fr 2fr;
    margin: 5px;
    border-radius: 10px;
    background-color: $themeColor4;
    align-items: center;
    max-height: 61px;

    .score-data {
      display: flex;
      flex-direction: column;

      .score.player1, .score.player2 {
        display: flex;
        margin: 4px;
        justify-content: flex-end;
        padding: 0 12px;
        color: $alpine;

        p {
          padding: 0;
          margin: 0 12px;
        }
      }
    }

    .teams-data {
      .container {
        display: flex;
        flex-direction: column;

        .team-data.first, .team-data.last {
          display: flex;
          margin: 4px;
          color: #a3a5ab;
          padding: 0 0 0 6px;

          p {
            padding: 0;
            margin: 0 12px;
          }
        }
      }
    }

    &.active {
      background-color: $themeColor6;
    }
  }
}

.live-table-content {
  width: 100%;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: $valhalla;
  height: inherit;
}

.live-table-data {
  padding: 0 12px;
  margin: 0 5px;
}

.live-table-data-titles {
  display: flex;
  font-size: 11px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 4px 12px;

  .team-name-title {
    width: 73px;
    margin-left: 3%;
    text-align: left;
  }

  .data-type {
    width: 28px;
    text-align: center;
    margin-left: 10px;
  }

  .data-types {
    display: flex;
  }
}

.live-table-data-info {
  display: flex;
  font-size: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
  align-items: center;
  border-top: 1px solid #75757e;
  color: #a3a5ab;
  justify-content: space-around;

  .points {
    width: 40px;
  }

  &.title {
    color: #f5f5f5;
    border-top: 0;

    p {
      color: #f5f5f5;
    }
  }

  .team-name {
    width: 105px;
    margin-left: 3%;
    text-align: center;
  }

  img {
    height: 20px;
    margin: 0 8px;
  }
}

.live-table-results {
  overflow: hidden;
  display: flex;
  color: #a3a5ab;
  margin: 0 5px;

  .table-result {
    width: 28px;
    text-align: center;
    margin-right: 10px;

    .triangle-up {
      position: relative;
      top: -10px;
      left: -5px;
      border-left: 3.5px solid transparent;
      border-right: 3.5px solid transparent;
      border-bottom: 4.5px solid #7be80f;
    }

    .triangle-down {
      position: relative;
      top: -10px;
      left: -6px;
      border-left: 3.5px solid transparent;
      border-right: 3.5px solid transparent;
      border-bottom: 4.5px solid #e82e2e;
    }
  }

  &.title {
    margin: 0 0 0 44px;

  }
}

.live-table-separate-line {
  width: 0.5px;
  height: 195px;
  background-color: #75757e;
  position: absolute;
  left: 60%;
}

.data-type {
  text-align: center;
  width: 10px;
}

::-webkit-scrollbar {
  width: 0;
  height: 0.5em;
}