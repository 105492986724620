.donut-chart {
  margin: 0 auto;
  border-radius: 50%;
  display: block;

  .donut-chart-track {
    fill: transparent;
    stroke: #272C36;
    stroke-width: 26;
  }

  .donut-chart-indicator {
    fill: transparent;
    stroke-width: 26;
    stroke-dasharray: 0;
    transition: stroke-dasharray .3s ease;
  }

  .donut-chart-text {
    padding-top: 10px;
    fill: #dcdcdc;
  }

  .donut-chart-text-val {
    font-size: 18px;
  }

  .donut-chart-text-percent {
    font-size: 14px;
  }

  .donut-chart-text-label {
    font-size: 9px;
  }
}