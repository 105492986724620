@import "../../styles/variables";

.providers-block {
  cursor: default;

  * {
    font-size: 12px;
    font-weight: bold;
    font-family: "Roboto", "Noto Sans Armenian", sans-serif;
  }

  .large-market-element-item.list-title {
    font-weight: normal;
  }

  .providers-header {
    display: flex;
    height: 35px;
    background-color: $themeColor4;
    color: #e6e7f5;
    justify-content: space-between;

    p {
      height: 35px;
      line-height: 35px;
      padding-left: 10px;
      margin: 0;
    }

    .providers-select {
      margin-right: 5px;
      margin-top: 2px;
    }
  }

  .pop-up-header {
    height: 50px;
    background-color: $themeColor4;
    text-underline: none;
    border-bottom: 2px solid $asset1;

    span {
      display: block;
      line-height: 50px;
      margin-left: 15px;
      font-size: 14px;
      float: left;
      font-weight: bold;
    }

    .pop-up-close {
      width: 18px;
      height: 18px;
      position: relative;
      display: block;
      float: right;
      cursor: pointer;
      right: 18px;
      top: 18px;
    }

    .pop-up-close:hover {
      opacity: 0.8;
    }

    .pop-up-close:before, .pop-up-close:after {
      position: absolute;
      content: ' ';
      height: 16px;
      width: 2px;
      background-color: #626a83;
    }

    .pop-up-close:before {
      transform: rotate(45deg);
    }

    .pop-up-close:after {
      transform: rotate(-45deg);
    }
  }

  .pop-up-search-header {
    display: flex;
  }

  .pop-up-search-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 13px;
    font-weight: bold;
  }
}