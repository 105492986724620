@import '../../../styles/variables';

.bet-slip-hint {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-weight: bold;
  color: white;
  animation: fadeIn 0.13s linear;
  z-index: 202;
  cursor: default;
  box-shadow: 2px 2px 8px #000000;
  background-color: transparent;
  background-image: -webkit-radial-gradient(calc(100%) 1vh, circle, transparent 1px, rgba(0, 0, 0, 0.6) 0);

  .container {
    position: relative;

    .close-hint {
      width: 33px;
      height: 33px;
      line-height: 32px;
      background-color: #362D42;
      border: 1px solid #524e5b;
      border-radius: 100%;
      text-align: center;
      font-size: 16px;
      position: absolute;
      right: 4vw;
      top: 50vh;
      cursor: pointer;

      &:active {
        background-color: #211c28;
      }
    }

    .hint-text {
      min-width: 35vw;
      max-width: 37vw;
      width: fit-content;
      height: fit-content;
      position: absolute;
      top: 60.5vh;
      left: 15vw;
      border-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: $betSlipPopUpBg;
      border: 0.2rem solid $betSlipPopUpBg;
      box-shadow: 0 0 0.1rem #f6d5eb, 0 0 0.1rem #fff, 0 0 0.5rem $betSlipPopUpBg, 0 0 0.2rem $betSlipPopUpBg, 0 0 0.7rem $betSlipPopUpBg;

      pre, p {
        line-height: 13px;
        font-size: 12px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .p {
        font-size: 13px;
      }
    }

    img {
      top: 65vh;
      left: 64vw;
      width: 25vw;
      position: absolute;
    }

    .dont-show {
      position: absolute;
      top: 80vh;
      left: 33vw;

      label {
        font-weight: bold;
        font-family: "Roboto", "Noto Sans Armenian", sans-serif;
        font-size: 14px;
      }
    }

    input {
      width: 22px;
      height: 18px;
      border-radius: 0;
      accent-color: var(--vs-asset1);
    }
  }
}