@import "../../../styles/variables";

$fontColor: #d2d3d9;

.history-pop-up {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #111521;
  z-index: 200;

  .backGround {
    position: absolute;
    top: 46px;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 15, 23, 0.62);
    z-index: 200;

    .video-preloader {
      width: 50px;
      height: 50px;
      display: block;
      position: absolute;
      top: 27%;
      left: 45%;
      border-radius: 50%;
      border-top: 3px solid #d2167e;
      border-right: 3px solid transparent;
      background: transparent;
      -webkit-animation: 0.5s spin linear infinite;
      animation: 0.5s spin linear infinite;
      z-index: 10
    }
  }

  .history-block-header {
    width: 100%;
    height: 45px;
    background-color: $themeColor0;
    border-bottom: 2px solid $asset1;
    position: absolute;
    top: 0;
    left: 0;

    .close-history-block {
      display: flex;
      position: absolute;
      right: 2px;
      line-height: 45px;
      margin-right: 18px;
      font-size: 18px;
      cursor: pointer;
    }

    p {
      width: 100%;
      float: left;
      font-size: 14px;
      text-align: center;
    }
  }

  .history-block-content {
    width: 100%;
    height: 100%;
    background-color: #1A212E;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .history-filters {
      width: 100%;
      padding-top: 47px;
      background-color: $themeColor3;
      flex-grow: 0;

      .history-filter {
        padding-left: 10px;
        padding-right: 10px;

        p {
          padding-left: 3px;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .custom-select {
          background-color: $themeColor5
        }

        .custom-select select {
          background-color: $themeColor5;
          line-height: 20px;
        }

        .custom-select:after {
          line-height: 30px;
          padding: 0 15px;
        }

        .matchId-input {
          width: 99%;
          border-radius: 5px;
          padding-left: 8px;
          border: 1px solid #444a5a;
          background-color: var(--vs-col5);
          box-sizing: border-box;
          margin-top: 2px;
          color: white;
          height: 30px;
        }
      }

      .filter-calendars {
        display: flex;
        width: 99%;
        border-radius: 5px;
        box-sizing: border-box;
        margin-bottom: 8px;

        .calendar-icon {
          width: 16px;
          height: 16px;
          float: right;
          right: 11px;
          margin-right: 8px;
          margin-top: 9px;
          position: absolute;
        }

        .search-calendar {
          border-radius: 5px;
          width: 100%;
          height: 30px;
          line-height: 30px;
          text-align: left;
          margin-top: 2px;
          padding-left: 5px;
          background-color: $themeColor5;
          border: 1px solid $themeColor5;

          .react-datepicker__tab-loop {
            .react-datepicker-popper {
              position: relative !important;
              will-change: transform;
              top: -30px !important;
              transform: translate3d(0px, 0px, 0px) !important;
              width: 0 !important;
            }
          }

          .react-datepicker-wrapper, .react-datepicker__input-container {
            width: 98%;

            input {
              height: 28px;
              background-color: transparent;
              outline: none;
              -webkit-appearance: none;

              &:focus {
                outline: none;
                -webkit-appearance: none;
              }

              :-moz-focusring {
                outline: none;
              }
            }
          }

          .react-datepicker, .filter-calendars {
            * {
              outline-width: 0;
              -webkit-appearance: none;
            }
          }

          .react-datepicker__triangle {
            visibility: hidden;
          }

          .react-datepicker__month-container {
            border-radius: 5px;
          }

          .react-datepicker, .react-datepicker__header {
            border: none;
            background-color: #181c23;
            color: white;
          }

          .react-datepicker__day, .react-datepicker__current-month, .react-datepicker__day-name {
            color: #969db0;
            border-radius: 0;
          }

          .react-datepicker__day--disabled {
            color: #494e5c;
          }

          .react-datepicker__day:hover {
            background-color: #2E323E;
            border-radius: 0;
          }

          .react-datepicker__day--selected {
            background-color: #0d1013;
          }

          .calendar-input {
            width: 100%;
            border: none;
            background-color: #212531;
            color: $fontColor;
            cursor: pointer;

            &:focus {
              -webkit-appearance: none;
            }

            &:hover {
              -webkit-appearance: none;
            }
          }
        }
      }

      .custom-select {
        width: 99%;
        border-radius: 5px;
        border: 1px solid #444a5a;
        box-sizing: border-box;
        margin-top: 2px;

        select {
          cursor: pointer !important;
          border-radius: 0 !important;
          background-color: #272b37;
        }
      }

      .show-button {
        width: 95%;
        border-radius: 4px;
        margin: 18px auto;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        color: white;
        background-color: #3a7e3a;

        &:hover {
          background-color: #4d884d;
        }
      }
    }

    .empty-history {
      float: left;
      width: 100%;
      margin-top: 30px;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
    }

    .bets-list-container {
      flex-grow: 1;
      overflow-y: auto;
      scroll-behavior: smooth;
      padding-bottom: 10px;

      .result-item {
        width: 98%;
        height: 72px;
        background: var(--vs-col5);
        margin-left: 1%;
        border-radius: 4px;

        &.match-result {
          height: 50px;
        }

        p {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          padding-left: 1.5vw;
          padding-right: 1.5vw;
          line-height: 0;
        }

        p:first-of-type {
          padding-top: 13px;
        }

        .score {
          color: #ff9834;
          line-height: 5px;
        }
      }
    }
  }

  .login-hint-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-top: 5px;
    padding-top: 8px;
    font-size: 13px;
    color: #d4d5dd;
    position: absolute;
    bottom: 0;
    background: #393c49;
    padding-bottom: 35px;

    .bet-warning-icon {
      width: 13px;
      height: 100%;
      float: left;
      margin: 1px 6px 1px 0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      flex-basis: 6%;
      max-height: 19px;
    }

    .bet-login-warning {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-basis: 80%;
    }

    span.text-button {
      float: left;
      margin: 0 6px;
    }

    .text-button {
      font-weight: bold;
      cursor: pointer;
      color: #dcac0f;

      &:hover {
        color: white;
      }
    }
  }
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $asset1 !important;
}

.history-pop-up .history-block-content .history-filters .filter-calendars .search-calendar .react-datepicker__day:hover {
  background-color: $asset1 !important;
}
