@import "../../../../styles/variables";

.my-bet-element {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2px;
  color: #cccdd9;

  .my-bet-element-title {
    display: flex;
    justify-content: space-between;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
    background-color: #464958;
    line-height: 30px;
    padding-left: 10px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #4c505f;
    }

    .open-close-arrow {
      width: 30px;
      cursor: pointer;
      margin-right: 10px;
      font-size: 14px;
      line-height: 0;
      font-weight: normal;
      color: #bbbcc6;

      .up {
        margin-top: 15px;
        transform: rotate(90deg);
      }

      .down {
        margin-top: 17px;
        transform: rotate(-90deg);
      }
    }
  }

  .my-bet-element-content {
    width: 100%;

    .my-bets-bet-item {
      display: flex;
      margin-bottom: 2px;
      flex-direction: column;
      background-color: #272a33;
      border-top: 1px solid #464958;

      .open-bet-stake-info, .open-bet-possible-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 23px 0 6px;
        height: 35px;
        color: #a9a9a9;

        p {
          margin: 0;
        }

        .ppossible_win {
          line-height: 15px;
          right: 26px;
          color: #a9a9a9;
        }
      }

      .line {
        border-bottom: thin solid #4f525a;
        width: 97%;
        margin-left: 6px;
      }

      .mb-item-header {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 5px;
        margin-top: 5px;
        width: 100%;
        height: 40px;

        .mb-time {
          height: 17px;
          margin: 3px 0 0 0;
          font-size: 11px;
          color: #afb8c9;
        }

        .mb-stadium-name {
          height: 20px;
          font-size: 12px;
          margin-top: 8px;
        }

        .mb-market-name {
          height: 20px;
          font-size: 12px;
          margin-top: 8px;
          color: #9ea1a9;
        }

        .clock-icon {
          position: absolute;
          right: 13px;
          top: 4px;
          width: 14px;
        }
      }

      .mb-type {
        height: 20px;
        font-size: 12px;
        color: #afb8c9;
        text-align: left;
        margin-top: 5px;
        padding-left: 5px;
      }

      .mb-item-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 5px;
        width: 95%;
        height: 40px;

        p {
          font-size: 12px;
        }

        .mb-coef {
          font-weight: bold;
          height: 20px;
          padding-right: 2px;
          font-size: 12px;
          color: #aa8136;
          margin-top: 4px;
        }
      }
    }
  }
}