@import '../../../styles/variables';

$selectColor: $themeColor4;
$textColor: #e6e7f5;

.custom-select {
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  cursor: pointer;
  font-weight: bold;
  font-family: "Roboto", "Noto Sans Armenian", sans-serif;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  select {
    width: 100%;
    text-indent: 12px;
    display: inline-block;
    float: left;
    color: $textColor;
    height: 30px;
    border-radius: 0;
    margin: 0;
    outline: none;
    background: $selectColor;
    cursor: pointer;
    border: 0;
    font-weight: bold;
    line-height: 20px;
    font-family: "Roboto", "Noto Sans Armenian", sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 3px 20px 3px 6px;
  }
}

.custom-select:after {
  content: "▼";
  position: absolute;
  font-size: 69%;
  background: unset !important;
  color: $textColor;
  pointer-events: none;
  padding-right: 8px;
  right: 0;
  line-height: 30px
}

.no-pointer-events .custom-select:after {
  content: none;
}
