@import "../../../styles/variables";

.mini-bet-slip {
  position: absolute;
  width: 100vw;
  background-color: white;
  z-index: 200;
  bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .keyboard-container {
    display: block;
    width: calc(100% - 10px);
    margin-left: 5px;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .place-process {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: #16161d54;

    .loader {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 45px;
      left: 43%;
      border-radius: 50%;
      background: transparent;
      border-top: 3px solid #d2167e;
      border-right: 3px solid transparent;
      animation: 0.5s spin linear infinite;
    }
  }

  .bet-item {
    height: 100px;
    padding: 3px 5px 0;
    position: relative;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .remove-item {
      color: #5D5D5DFF;
      position: absolute;
      right: 4px;
      top: 1px;

      &:hover {
        color: #7a7a7a;
      }

      &:active {
        color: #505050;
      }
    }

    .bet-type {
      margin: 2px 0 2px 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 96%;
      overflow: hidden;
    }

    .potential-winning-container {
      width: 100%;
      margin-top: 0;
      background-color: white;

      .potential-win-value {
        color: #18ab35;
      }
    }

    .price-info {
      display: flex;
      justify-content: space-between;

      span {
        color: #aa8136;
      }
    }

    .bet-stadium-name, .bet-type, .price-info, .potential-win-title {
      color: #5D5D5DFF;
    }
  }

  .stake-block {
    display: block;
    margin-left: 8px;
    margin-top: 5px;
    width: calc(100% - 15px);
    justify-content: space-between;
    height: 40px;

    .stake-block-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    input {
      width: 35%;
      box-sizing: border-box;
      background: #C8C8C8;
      color: #1f1f1f;
      border: none;
      height: 30px;
      float: left;
      font-size: 12px;
      line-height: 32px;
      padding: 0 10px;
      outline: none;
      text-align: center;

      &:focus {
        background: #afafaf;
      }
    }

    .stake-button {
      width: 10%;
      background: #757575;
      border-radius: 4px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      float: left;

      &:hover {
        opacity: 0.95;
      }

      &:active {
        background: #626262;
      }

      &.minus, &.plus {
        color: #1f1f1f;
        background-color: #C8C8C8;

        &:active {
          background: #b4b4b4;
        }
      }

      &.minus {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -1.5%;
      }

      &.plus {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1.8%;
      }
    }
  }

  .login-hint {
    width: 96%;
    margin-left: 2%;
    margin-top: -5px;
    margin-bottom: 5px;
    border-radius: 4px;
    line-height: 26px;
    text-align: center;
    background-color: #d79826;

    .bet-login-warning {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-basis: 80%;
    }

    span.text-button {
      float: left;
      margin: 0 4px;
    }

    .text-button {
      font-weight: bold;
      cursor: pointer;
      color: white;

      &:active {
        opacity: 0.8;
      }
    }
  }

  .place-bet-container {
    position: relative;

    .place-bet {
      width: 50%;
      margin-left: 25%;
      text-align: center;
      line-height: 30px;
      background-color: #1B7051;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 10px;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        background-color: #15573f;
      }

      &.disable {
        opacity: 0.6;
        cursor: default;
        pointer-events: none;
      }
    }

    .keep-in {
      display: flex;
      position: absolute;
      top: 0;
      right: 8%;
      height: 30px;
      width: 30px;
      background-color: #757575;
      font-weight: normal;
      cursor: pointer;
      font-size: 13px;
      text-align: center;
      border-radius: 100%;

      .keep-in-container {
        position: relative;

        img {
          width: 15px;
          padding-left: 8px;
          padding-top: 6px;
        }

        img.hover {
          position: absolute;
          box-shadow: 0 0 5px #3c3434;
          padding: 0;
          width: 13px;
          left: 0;
          animation-name: animateBetTicket;
          animation-duration: 0.5s;
        }

        @keyframes animateBetTicket {
          0% {
            bottom: 0
          }

          100% {
            bottom: 30vh;
            transform:  rotate(45deg);
            transform-origin: 40px 10px;
          }
        }

        p {
          position: absolute;
          top: -14px;
          right: -12px;
          width: 15px;
          height: 15px;
          background-color: var(--vs-asset1);
          border-radius: 100%;
          font-size: 11px;
        }
      }
    }
  }

  .warning {
    width: calc(100% - 24px);
    margin-top: 10px;
    margin-left: 8px;
    border-radius: 4px;
    background-color: #e50303;
    line-height: 27px;
    padding-left: 8px;
    text-align: center;
  }

  .success {
    width: 96%;
    height: 34px;
    line-height: 32px;
    margin-left: 2%;
    background: #158500;
    margin-top: 9px;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
  }
}