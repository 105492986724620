.keyboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  .button {
    flex: 20% 1;
    max-width: 24%;
    background: #727272;
    margin: 3px;
    height: 29px;
    line-height: 29px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    color: white;
    overflow: hidden;

    div {
      color: #0fc457;
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }

    .backspace {
      padding-top: 9px;
      height: 11px;
      width: 15px;
    }

    .edit {
      padding-top: 8px;
      width: 15px;
    }
  }

  input {
    flex: 20% 1;
    max-width: 20%;
    box-sizing: border-box;
    background: var(--vs-col4);
    border: none;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    color: #c1c4cd;
    outline: none;
    border-radius: 4px;
    text-align: center;
    margin: 3px;

    &:focus {
      background: var(--vs-col5);
    }
  }
}