@import "../../../styles/variables";

.basic-market {
  width: 100%;

  .marble-icon {
    float: left;
    width: 15px;
    height: 15px;
    border-radius: 30px;
    margin: 7px;
  }

  .basic-market-container {
    width: 100%;
    float: left;
    display: flex;
    cursor: default;
  }

  .match-running-info {
    width: 90%;
    margin-left: 5%;
    float: left;
    text-align: center;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 21px;
    color: #a7abba;
    font-weight: bold;
  }

  .player-item {
    min-width: 220px;
    padding-right: 20px;
    text-align: left;

    embed {
      float: left;
      margin-left: 5px;
      padding: 0;
    }

    p {
      float: left;
      padding-left: 10px;
    }
  }
}

