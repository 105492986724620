@import '../../../styles/variables';

.bet-item {
  display: flex;
  flex-direction: column;
  padding: 7px;
  margin-top: 1px;
  font-size: 12px;
  font-weight: bold;
  background-color: $themeColor4;
  cursor: default;
  color: #c0c0cb;
  animation: fadeIn 0.25s linear;

  .bet-input-container {
    display: flex;
    flex-direction: row;
    margin-top: 2px;
  }

  .stake-block {
    width: 100%;
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
    justify-content: space-between;

    input {
      width: 35%;
      box-sizing: border-box;
      color: #1f1f1f;
      background: #C8C8C8;
      border: none;
      height: 32px;
      float: left;
      font-size: 12px;
      line-height: 32px;
      padding: 0 10px;
      outline: none;
      text-align: center;

      &:focus {
        background: #afafaf;
      }
    }

    .stake-button {
      width: 10%;
      background: #757575;
      border-radius: 4px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      color: white;

      &:hover {
        opacity: 0.95;
      }

      &:active {
        opacity: 0.8;
      }

      &.minus, &.plus {
        color: #1f1f1f;
        background-color: #C8C8C8;

        &:active {
          background: #b4b4b4;
        }
      }

      &.minus {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -1.6%;
      }

      &.plus {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1.8%;
      }
    }
  }

  .potential-winning-container {
    width: 98%;
    float: left;
    color: #efefef;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $themeColor4;
    cursor: default;

    .potential-win-title {
      font-size: 12px;
      margin-bottom: 5px;
    }

    .potential-win-value {
      font-size: 12px;
      margin-bottom: 5px;
      color: #1bb446;
    }

    .win-info {
      display: flex;
      width: 95%;
      justify-content: space-between;
      border-top: 1px solid #474b5e;
    }
  }

  .bet-input {
    width: 96%;
    margin: 0 5px;
    box-sizing: border-box;
    background: $themeColor2;
    border: none;
    height: 32px;
    float: left;
    box-shadow: 0 0 0 1px #505768 inset;
    font-size: 12px;
    line-height: 30px;
    color: #c1c4cd;
    padding: 0 10px;
    outline: none;
  }

  .max-bet-button {
    float: left;
    width: 27%;
    height: 32px;
    line-height: 32px;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    background-color: #c69130;
    cursor: pointer;

    &:hover {
      background-color: #e5a630;
    }

    &:active {
      background-color: #cf911e;
    }

    &.disabled {
      background-color: #36363c;
      border: 1px solid #3a3d4a;
      color: #636777;
      cursor: default;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }


  @keyframes fadeIn {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }

  #failed {
    fill: red;
  }

  p {
    margin: 5px;
    height: 15px;
    float: left;
    text-align: left;
  }

  .bet-item-header {
    display: block;
    flex-direction: row;
    width: 100%;

    .bet-stadium-name {
      white-space: pre;
      max-width: 92%;
      overflow: hidden;
    }

    .checkbox {
      float: left;
      margin-top: 6px;
      margin-left: 6px;
      background: $themeColor2;
    }

    .remove-item {
      float: right;
      margin-right: 5px;
      margin-top: 5px;
      cursor: pointer;

      &:hover {
        color: white;
      }
    }

    .bet-failed-icon {
      width: 11px;
      height: 11px;
      float: left;
      margin-left: 6px;
      margin-top: 7px;
      background-position: center;
      background-size: 100%;
    }
  }

  .price-info {
    color: white;
  }

  .bet-type {
    float: left;
    text-align: left;
    margin: 3px 3px 2px 5px;
    color: #80879b;
  }

  .bet-item-footer {
    display: block;
    margin-bottom: 2px;

    .bet-coef {
      float: right;
      margin-right: 10px;

      .old-odd, .new-odd {
        margin: 15px;
        font-size: 14px;
        padding: 12px;
      }

      .old-odd {
        text-decoration: line-through;
        color: #8c6d33;
        text-decoration-color: red;
      }
    }

  }

  .event-status {
    display: flex;
    justify-content: start;
    align-items: baseline;
    margin: 5px 0 2px 2px;

    .bet-failed-icon {
      width: 11px;
      height: 11px;
      float: left;
      margin: 0 6px 0 3px;
      background-position: center;
      background-size: 100%;

    }

    .dell-text {
      color: #eb1a1a;
      font-size: 10px;
      margin: auto 0;
      font-weight: bold;

      &.suspended {
        color: #886502;
      }
    }
  }

  .bet-time, .bet-item-footer {
    color: #aa8136;
  }
}