@import "../../../styles/variables";

$borderRadius: 5px;
$videoHeight: 310px;
$iconsSize: 15px;
$mainBackgroundColor: rgba(0, 0, 0, 0.5);
$sliderArrowsRadius: 10px;
$backgroundColor: #212432;

.video-item {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;

  .video-player {
    display: flex;
    width: 100%;
    height: auto;
    z-index: 0;

    &.rotate {
      width: 100% !important;
      height: 100% !important;
      transform: rotate(90deg) !important;
      background-color: red !important;
    }

  }

  .overlay {
    height: 100%;
    width: 100%;
    background-color: $themeColor1;
    cursor: pointer;
    z-index: 97;
    position: absolute;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: 15vmin;
    transition: opacity 0.2s linear;
    -webkit-transition: opacity 0.2s linear;
  }

  .place-bet-text {
    position: absolute;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2em;
    padding: 20px 0 0;
    color: #d6d6d6;
    text-shadow: 2px 2px 2px #464646;
    z-index: 97;
    bottom: 39px;
  }

  video::-webkit-media-controls-timeline {
    display: none;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &.big {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .video-preloader {
    width: 51px;
    height: 51px;
    display: block;
    position: absolute;
    top: 37%;
    left: 42.5%;
    z-index: 99;
    border-radius: 50%;
    border-top: 3px solid #d2167e;
    border-right: 3px solid transparent;
    background: transparent;
    -webkit-animation: 0.5s spin linear infinite;
    animation: 0.5s spin linear infinite;
  }
}

video {
  height: $videoHeight;
  outline: none;
  background-color: $backgroundColor !important;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background: $asset1;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background: $asset1;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background: $asset1;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}