@import '../../../styles/variables';

.max-bet-container {
  width: 100%;
  float: left;
  font-family: "Roboto", "Noto Sans Armenian", sans-serif;
  min-height: 350px;

  .stake-block {
    width: 96%;
    margin-left: 2%;
    display: flex;
    margin-top: 5px;
    margin-bottom: 8px;
    justify-content: space-between;

    input {
      width: 35%;
      box-sizing: border-box;
      background: #C8C8C8;
      color: #1f1f1f;
      border: none;
      height: 32px;
      float: left;
      font-size: 12px;
      line-height: 32px;
      padding: 0 10px;
      outline: none;
      text-align: center;

      &:focus {
        background: #afafaf;
      }
    }

    .stake-button {
      width: 10%;
      background: #757575;
      border-radius: 4px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      color: white;

      &:hover {
        opacity: 0.95;
      }

      &:active {
        opacity: 0.8;
      }

      &.minus, &.plus {
        color: #1f1f1f;
        background-color: #C8C8C8;

        &:active {
          background: #b4b4b4;
        }
      }

      &.minus {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -1.6%;
      }

      &.plus {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1.8%;
      }
    }
  }

  .system-calculator-block {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 92%;
    height: 50px;
    padding-top: 10px;
    margin: auto;

    .system-coef {
      width: 8%;

      span {
        min-width: 45%;
        display: inline-block;
        width: fit-content;
      }
    }

    input.max-bet-input {
      margin-left: 9px
    }

    .stake-block {
      width: 90%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }

  .max-bet-button {
    float: left;
    width: 27%;
    height: 32px;
    line-height: 32px;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    background-color: #c69130;
    cursor: pointer;

    &:hover {
      background-color: #e0ab2e;
    }

    &.disabled {
      background-color: #36363c;
      border: 1px solid #3a3d4a;
      color: #636777;
      cursor: default;
    }
  }

  .bet-options {
    display: flex;
    width: 96%;
    float: left;
    flex-wrap: wrap;

    .bet-option {
      flex: 1 0 20%;
      height: 30px;
      font-size: 12px;
      line-height: 30px;
      margin-right: 10px;
      font-weight: bold;
      background-color: $asset3;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: $asset2;
      }
    }

    .bet-option-input {
      flex: 1 0 20%;
      width: inherit;
      height: 28px;
      padding: 0;
      line-height: 28px;
      font-size: 12px;
      margin-right: 10px;
      font-weight: bold;
      text-align: center;
      color: #c1c4cd;
      outline: none;
      background-color: #2F323E;
      border: 0.1px solid #4b4b5d;
    }
  }

  .edit-options {
    width: 22%;
    height: 27px;
    line-height: 26px;
    cursor: pointer;
    border: 1.5px solid #3a3d4a;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;

    &:hover {
      border: 1.5px solid #636777;
    }
  }

  .bet-options-edit {
    display: flex;
    width: 96%;
    height: 30px;
    float: left;
    text-align: center;

    .bet-option-edit {
      width: 24%;
      height: 30px;
      line-height: 30px;
      font-weight: bold;
      background-color: #1f2437;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 10px;
      text-align: center;
    }
  }

  .place-bet-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 55px;
    background-color: var(--vs-col2);
    border-top: 1px solid #414660;
    padding-bottom: 40px;

    &.long {
      height: 200px;
    }

    .container {
      position: relative;
      height: inherit;
      padding-left: 2%;

      .keyboard {
        width: 96%;
        bottom: 52px;
        position: absolute;
      }
      
      .place-bet {
        display: flex;
        width: 95%;
        height: 35px;
        line-height: 35px;
        float: left;
        margin-top: 10px;
        border: 1px solid #3a3d4a;
        color: #636777;
        justify-content: center;
        font-size: 13px;
        font-weight: bold;
        cursor: default;
        position: absolute;
        bottom: 10px;
        border-radius: 4px;

        &.enabled {
          width: 96%;
          cursor: pointer;
          background-color: $asset2;
          border: 0;
          color: white;

          &:hover {
            background-color: #27a26e;
          }
        }
      }
    }

    .bet-warning {
      display: flex;
      float: left;
      width: 95%;
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      font-weight: bold;
      background-color: #9c9cac;
      border: 1px solid #9c9cac;
      color: #222226;
      cursor: default;
      justify-content: center;
      animation: fadeIn 0.7s linear;
      transaction: all 0.7s linear;
      margin-top: -8vw;
      border-radius: 4px;
    }

    @keyframes fadeOut {
      0% {
        opacity: 1
      }
      100% {
        opacity: 0
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0
      }
      100% {
        opacity: 1
      }
    }

    .bet-warning.fadeOut {
      animation: fadeOut 0.7s linear 3s;
      animation-fill-mode: forwards;
    }
  }

  .potential-winning-container {
    width: 94%;
    float: left;
    color: white;
    display: flex;
    margin-left: 2%;
    border-radius: 4px;
    flex-direction: column;
    background-color: $themeColor4;
    cursor: default;
    padding-left: 11px;

    .potential-win-title {
      font-size: 12px;
      margin-bottom: 5px;
    }

    .potential-win-value {
      font-size: 16px;
      margin-top: 2px;
      margin-bottom: 11px;
    }

    .win-info {
      display: flex;
      width: 95%;
      justify-content: space-between;
      border-top: 1px solid #474b5e;
    }
  }
}