:root {
  --main-bg: #030C13;
  --vs-col0: #161C28;
  --vs-col1: #16161d;
  --vs-col2: #1e222d;
  --vs-col3: #292C38;
  --vs-col4: #2f323e;
  --vs-col5: #404556;
  --vs-col6: #5a5e6d;

  --vs-asset1: #d8278f;
  --vs-asset01: #791d4e;
  --vs-asset2: #008D47;
  --vs-asset3: #1cbe52;
  --vs-asset4: #aa8136;
  --vs-asset7: #aa8136;
}

//// Colors
//$mainBackgroundColor: #212532;
$valhalla-dark: #16161d;
$valhalla-lite-dark: #20202b;
$valhalla-medium: #292C38;
$valhalla: #2F323E;
$alpine: #aa8136;


// Skin colors
$mainBackgroundColor: var(--main-bg);
$themeColor0: var(--vs-col0);
$themeColor1: var(--vs-col1);
$themeColor2: var(--vs-col2);
$themeColor3: var(--vs-col3);
$themeColor4: var(--vs-col4);
$themeColor5: var(--vs-col5);
$themeColor6: var(--vs-col6);
$betSlipPopUpBg: var(--vs-col7);

// Accent colors
$asset1: var(--vs-asset1);
$asset01: var(--vs-asset01);
$asset2: var(--vs-asset2);
$asset3: var(--vs-asset3);
$alpine: var(--vs-asset4);

// Sizes
$providersBlockHeight: 36px;
$sportsBlockHeight: 71px;




