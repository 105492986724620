@import "../../styles/variables";

.sports-block {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;

  .empty-data {
    width: 100%;
    color: orange;
    text-align: center;
    padding-top: 30px;
    opacity: 0;
    -webkit-animation: 2s ease 2s normal forwards 1 fadein;
    animation: 2s ease 2s normal forwards 1 fadein;
  }

  .title {
    flex: 0 0 100%;
    height: 35px;
    line-height: 35px;
    background-color: #252937;
    border-bottom: 2px solid #1a1c26;
    padding-left: 10px;
  }

  .sport-block {
    width: calc(50% - 3px);
    background-color: $themeColor3;
    font-size: 13px;
    font-weight: bold;
    border-bottom: 2px solid $themeColor0;
    border-left: 2px solid $themeColor0;
    cursor: default;

    .sport-block-title {
      height: 26px;
      line-height: 22px;
      text-align: center;
    }

    img {
      width: 100%;
      height: auto;
      background-color: $themeColor0;
      min-height: 80px;
      min-width: 98%;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0
    }
    to {
      opacity: 1
    }
  }
}