@import "../../styles/variables";

.instants-block {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;

  .item {
    width: calc(49% - 3px);
    background-color: #252937;
    font-size: 13px;
    font-weight: bold;
    border-bottom: 2px solid #1a1c26;
    border-left: 2px solid #1a1c26;
    cursor: default;

    .item-title {
      height: 30px;
      line-height: 29px;
      text-align: center;
    }

    img {
      width: 100%;
      height: auto;
      background-color: #1a1c26;
      min-height: 80px;
      min-width: 98%;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}