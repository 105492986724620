@import "../../../styles/variables";

$borderRadius: 3px;
$stadiumBlockHeight: 30px;
$matchDayBlockHeight: 45px;

.select-matchday {
  width: 100%;
  height: $matchDayBlockHeight;
  display: flex;
  background-color: $themeColor4;
  border-radius: 2px;

  .custom-select select:after {
    padding: 12px;
    line-height: 30px;
  }

  p {
    width: 50%;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    margin-left: 10px;
    cursor: default;
    margin-top: 0;
    line-height: 48px;
  }

  .matchday-addition-info p {
    margin-top: 0;
    margin-left: 3px;
  }

  .stadium-name {
    display: flex;
    float: left;
    width: 100%;
    height: $stadiumBlockHeight;
    border-top: 1px solid $mainBackgroundColor;
    background-color: #161C28;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;

    p {
      margin: 0;
      padding-left: 10px;
      float: left;
      font-size: 13px;
      line-height: $stadiumBlockHeight;
    }
  }
}

.matchday-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  width: 50%;
  height: 5vh;

  &.custom-select {
    width: 50%;
    height: $matchDayBlockHeight;
    border-radius: 0;

    select {
      height: 100%;
      padding-bottom: 0;
      border-radius: 0;
    }
  }
}

.matchday-button {
  width: 22px;
  height: 20px;
  font-size: 11px;
  background-color: #dedee3;
  cursor: pointer;
  text-align: center;
  line-height: 20px;
  margin-right: 4px;
  color: #1c1c2a;
  font-weight: bolder;
}

.matchday-button:hover {
  background-color: white;
}

.active-round, .active-round:hover, .active-round:active {
  background-color: #d43b94;
  color: white;
  cursor: default;
}

.disabled, .disabled:hover {
  color: #656565;
  background-color: #a8a8b6;
  cursor: default;
}