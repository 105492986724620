@import "../../../../styles/variables";

.basic-market-list {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;

  .basic-market-list-item {
    float: left;
    padding: 5px;
    font-size: 12px;
    border-bottom: 1px solid #333348;

    img {
      float: left;
      margin: 0;
      padding: 0;
      width: 22px;
      height: 30px;
      padding-left: 5px;
    }
  }

  .list-title {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    background-color: $themeColor2;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 2px solid #212532;

    .arrow-icon {
      float: right;
      margin-top: 12px;
      margin-right: 10px;
      border: solid #6f6e7b;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;

      &.up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }

      &.down {
        margin-top: 10px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }

  .list-info-item {
    background-color: $themeColor4;
  }

  .price {
    color: #aa8136;
    height: 30px;
    font-size: 12px;

    p {
      float: right;
      padding-right: 10px;
      line-height: 5px;
    }

    .player-name {
      float: left;
      color: white;
      padding-left: 15px;
    }
  }

  .price:hover {
    background-color: #303545;
    cursor: pointer;
  }

  .active, .active:hover {
    color: white;
    background-color: $asset1;
  }

  .active:hover {
    cursor: default;
  }
}