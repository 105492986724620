@import '../../styles/variables';

.bet-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: "Roboto", "Noto Sans Armenian", sans-serif;
  color: white;
  cursor: default;

  .tabs {
    display: none;
    height: 35px;
    align-items: center;
    padding: 0 12px;
    border-bottom: 3.3px solid $asset1;

    margin: 13px 6px 17px 6px;

    .main-tabs {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      height: 40px;

      .main-tab {
        margin: 0 12px;
        min-width: 45%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-5px) skew(350deg, 0deg);
        box-shadow: 5px 5px 11px transparent;
        font-size: 10px;
        -webkit-transition: all 0.1s cubic-bezier(0, 0, 1, 0.23);
        -moz-transition: all 0.1s cubic-bezier(0, 0, 1, 0.23);
        -o-transition: all 0.1s cubic-bezier(0, 0, 1, 0.23);
        transition: all 0.1s cubic-bezier(0, 0, 1, 0.23);

        &.active-tab {
          background: linear-gradient(45deg, $asset1 0, $asset01 100%);
          box-shadow: 5px 5px 11px black;
          transform: translateX(5px) skew(345deg, 0deg);
        }
      }

      &.single {
        justify-content: flex-start;
      }
    }


  }

  .thumbnails {
    display: flex;
    height: 400px;
    background-color: #16161d;
  }

  .bet-footer {
    margin-top: 35px;
    margin-bottom: 75px;

    .back-to-top {
      width: 85%;
      border: 1px solid #75757e;
      padding: 10px;
      margin: auto;
      text-align: center;
      font-size: 12px;
      cursor: pointer;

      &:active {
        opacity: 0.7;
      }
    }

    .bet-logo {
      height: 18px;
      display: flex;
      margin: 25px auto auto;
    }
  }
}