@import "../../../../styles/variables";

$radius: 1px;

.market-tab-menu {
  display: flex;
  width: 100%;
  border-radius: $radius;
  justify-content: space-between;

  .market-tabs {
    display: flex;
    width: 100%;
    margin-bottom: 3px;

    .market-tab {
      width: 100%;
      justify-content: center;
      border-radius: $radius;
      background-color: $themeColor4;
      cursor: pointer;
      font-size: 12px;
      color: #c4c6c8;
      align-items: baseline;
      border-bottom: 3px solid $themeColor3;
      margin-left: 2px;
      margin-right: 2px;
      min-width: 25px;
      height: 35px;
      line-height: 35px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;

      p {
        margin-left: 3px;
      }

      span {
        &.bookmarks-count {
          position: relative;
          top: -5px;
          font-size: 10px;
          margin: 2px;
          color: #e6e7f5
        }
      }
    }

    .market-tab-menu-bookmark {
      width: 60px;
      padding: 0 15px 0 15px;
      font-size: 18px;
      color: $alpine;
      display: flex;
      align-items: center;

      @if & {
        &.active-tab {
          color: #d49f27;

        }
      }
    }

    .market-tab:hover {
      background-color: $themeColor2;

      @if & {
        &.market-tab-menu-bookmark {
          color: $alpine;
        }
      }
    }

    .active-tab, .active-tab:hover {
      color: white;
      background-color: $themeColor2;
      border-bottom: 2px solid $asset1;
    }

    .active-tab:hover {
      cursor: default;
    }
  }
}