@import "../../../styles/variables";

$additionInfoHeight: 25px;
$stadiumBlockHeight: 30px;
$upcomingGamesHeight: 38px;
$borderRadius: 3px;

.upcoming-games {
  width: 100%;
  height: 44px;
  float: left;
  background-color: $themeColor2;
}

.addition-info {
  background-color: #1A212E;
  border-bottom: 1px solid seagreen;
  border-top-right-radius: $borderRadius;
  border-top-left-radius: $borderRadius;

  img {
    float: left;
    width: 20px;
    height: 12px;
    padding-top: 6px;
    padding-left: 4px;
  }
}

.addition-info p {
  float: left;
  font-size: 12px;
  color: #BBBBBE;
  line-height: $additionInfoHeight;
}

.upcoming-games-content {
  width: 100%;
  height: $upcomingGamesHeight;
}

.upcoming-games-times {
  width: 100%;
  margin-top: 3px;
  display: flex;
}

.game-start-time {
  flex: 1;
  display: flex;
  justify-content: center;
  line-height: 24px;
  margin: 2px;
  border-radius: 1px;
  padding: 5px 6px 3px 6px;
  font-size: 12px;
  background-color: $themeColor4;
  cursor: pointer;
  font-weight: bold;

  &.active {
    background-color: $asset1;
    border-bottom: 2px solid $asset1;
  }
}

.stadium-name {
  height: $stadiumBlockHeight;
  background-color: $themeColor2;
  border-top: 1px solid $themeColor1;
  border-bottom-right-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
}

.stadium-name p {
  float: left;
  margin-left: 8px;
  font-size: 13px;
  line-height: $stadiumBlockHeight;
}