@import "../../../styles/variables";

$color: #b6b6bb;

.league-market {
  .matches-data-is-empty {
    height: 5vh;
    font-size: 13px;
    text-align: center;
    line-height: 5vh;
  }

  .league-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $themeColor0;

    .league-menu-tab {
      display: flex;
      margin-top: 4px;
      text-align: left;
      font-size: 12px;
      flex-direction: column;
      justify-content: space-between;

      .arrow {
        float: right;
        height: 0;
        padding: 3px;
        margin-top: 14px;
        margin-right: 15px;
        border: solid #6f6e7b;
        border-width: 0 3px 3px 0;
        display: inline-block;

        &.up {
          margin-top: 18px;
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }

        &.down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }

      .league-menu-tab-title {
        display: flex;
        flex-flow: wrap;
        flex-direction: row;
        box-sizing: border-box;
        justify-content: space-between;
        cursor: pointer;
        color: $color;
        background-color: $themeColor4;

        p {
          margin-left: 8px;

          span, .team-data {
            display: flex;
            float: left;

            &.first, &.last {
              width: 110px;
              line-height: 19px
            }

            p {
              padding-top: 3px;
            }

            img {
              height: 20px;
              margin: 0 8px;
            }
          }

          span {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }

      .league-market-content {
        width: 100%;
        display: flex;
        margin-top: 4px;
        text-align: center;
        margin-bottom: 5px;

        .large-market-container {
          width: 98%;
          margin-top: 4px;
          margin-left: 1%;
        }
      }

      .league-market-content-hidden {
        display: none;
      }
    }
  }
}