@import "../../../../styles/variables";

.player-statistic {
  display: flex;
  width: 100%;
  height: 130px;
  border-top: 2px solid #101218;

  .statistic-block {
    border-right: 2px solid #101218;
  }

  .player-info {
    width: 25%;
    position: relative;

    p {
      left: 10px;
      bottom: 10px;
      position: absolute;
    }

    .player-info-container {
      display: flex;
      width: 50%;

      .player-img {
        height: 80px;
        top: 40px;
        left: 5px;
        position: absolute;
      }

      p {
        position: absolute;
        left: 100px;
      }
    }
  }

  .player-games-info {
    width: 70%;

    .top-menu {
      height: 30px;
      display: flex;
      border-bottom: 2px solid $themeColor2;
      justify-content: flex-end;

      &.custom-select {
        padding-left: 5px;
        background-color: $themeColor2;

        &:after {
          font-size: 11px;
          padding: 0 10px;
        }

        select {
          width: 55px;
          background-color: $themeColor2 !important;
        }
      }
    }

    .content {
      display: flex;

      .icons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 12%;
        margin-top: 8px;

        .stat-icon {
          line-height: 25px;
          margin-bottom: 12px;
          margin-left: 8px;
          border-radius: 8px;
          background-color: #4c515a;
          font-size: 11px;
          text-align: center;

          &.win {
            background: rgb(111, 24, 225);
            background: linear-gradient(180deg, rgba(111, 24, 225, 1) 0%, rgb(64, 42, 134) 100%, rgb(48, 33, 90) 000326%);
          }

          &.lost {
            background: #ef5293;
            background: linear-gradient(180deg, rgb(238, 62, 134) 0%, rgb(187, 46, 103) 100%, rgb(121, 28, 66) 000326%);
          }
        }
      }
    }

    .chart-container {
      width: 88%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 13px;
      height: 80px;
      position: relative;

      .points-container {
        position: relative;

        .point {
          width: 12px;
          height: 12px;
          border-radius: 20px;
          border: 3px solid #BCBCBC;
          background: rgb(111, 24, 225);
          background: linear-gradient(180deg, rgba(111, 24, 225, 1) 0%, rgb(64, 42, 134) 100%, rgb(48, 33, 90) 000326%);
          position: absolute;
          top: 11px;

          &.L {
            top: 47px;
            background: #ef5293;
            background: linear-gradient(180deg, rgb(238, 62, 134) 0%, rgb(187, 46, 103) 100%, rgb(121, 28, 66) 000326%);
          }
        }

        .line {
          position: absolute;
          top: 19px;
          height: 3px;
          background-color: #BCBCBC;
        }
      }
    }
  }

  .player-wins-info {
    width: 30%;

    .title {
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      color: #868b93;
      border-bottom: 2px solid #101218;
      padding-left: 10px
    }

    .content {
      margin-top: 7px;
    }
  }
}