@import "../../styles/variables";

$radius: 2px;

.bet-slip-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #111521;
  color: #c1c4cc;
  z-index: 200;

  .bets-tabs {
    display: flex;
    width: 100%;

    .bets-tab {
      display: flex;
      width: 100%;
      justify-content: center;
      background-color: $themeColor4;
      cursor: pointer;
      font-size: 13px;
      height: 45px;
      line-height: 40px;
      color: #9ea1a9;
      border-bottom: 1px solid #1c1f28;
    }

    .bets-tab-menu-bookmark {
      width: 60px;
      padding: 0 15px 0 15px;
      font-size: 22px;
      color: $alpine;

      &.active-tab {
        color: #b18636;
      }

      .bookmarks-count {
        width: 16px;
        height: 16px;
        font-size: 10px;
        border-radius: 50px;
        background-color: rgba(117, 117, 117, 0.5);
        margin-top: 5px;
        line-height: 15px;
        color: white;
        position: absolute;
        left: 34px;
      }
    }

    .bets-tab:hover {
      background-color: $themeColor5;

      @if & {
        &.bets-tab-menu-bookmark {
          color: $alpine;
        }
      }
    }

    .active-tab, .active-tab:hover {
      color: white;
      height: 43px;
      background-color: $themeColor0;
      border-bottom: 2px solid $asset1;
    }

    .active-tab:hover {
      cursor: default;
    }
  }

  .bet-slip-block-content {
    width: 100%;
    height: 100%;
    background-color: $themeColor2;

    .bet-slip-block-content-header {
      display: flex;
      justify-content: space-between;

      .bets-tab-menu {
        width: 80%;
      }

      .bets-slip-settings {
        width: 100%;
        padding: 0 10px 15px;
        background-color: $themeColor5;
        border-top: 1px solid #232732;
        font-size: 14px;

        .radio {
          margin-top: 4px;
          margin-bottom: 4px;

          input {
            margin-right: 8px;
            margin-left: 0 !important;
          }
        }
      }

      .bet-slip-header {
        width: 20%;
        height: 45px;
        background-color: $themeColor3;
        border-bottom: 1px solid #171a21;

        .close-bet-slip {
          display: flex;
          float: right;
          line-height: 45px;
          margin-right: 18px;
          font-size: 18px;
          cursor: pointer;
        }

        .settings-button {
          height: 17px;
          float: right;
          margin-top: 14px;
          margin-right: 15px;
          cursor: pointer;

          &:hover, &:active {
            opacity: 0.7;
          }
        }

        .quick-bet-block {
          display: flex;
          float: left;

          .bet-switcher {
            margin-right: 0;
            margin-top: 5px;
            margin-left: 1px;
          }

          p {
            font-size: 14px;
            margin-left: 10px;
          }
        }
      }
    }

    .bets-list-container {
      float: left;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      display: block;
      height: inherit;
      box-sizing: border-box;
      padding-bottom: 50px;

      .bets-settings {
        width: 100%;
        height: 50px;
        background-color: $themeColor4;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .clear-bet-items {
          float: right;
          text-align: right;
          cursor: pointer;
          color: #c0c0cb;
          margin-right: 15px;
          padding-bottom: 3px;
          border-bottom: 1.5px solid #c0c0cb;

          &:hover {
            color: #eaeaf1;
          }
        }

        .system-select {
          display: flex;
          justify-content: space-between;

          .custom-select {
            width:104px;
            border: thin solid $themeColor3;
            margin-left: 12px;
            background-color: $themeColor3
          }

          .custom-select select {
            background-color: $themeColor3;
            text-indent: 3px;
            line-height: 20px;
          }

          .custom-select:after {
            line-height: 30px;
          }
        }
      }

      .bet-odds-block {
        height: 18px;

        p {
          float: right;
          font-size: 13px;
          padding-bottom: 0;
          font-weight: bold;
          margin-right: 10px;

          b {
            color: $alpine;
          }
        }
      }
    }

    .login-hint-block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 70px;
      margin-top: 5px;
      padding-top: 8px;
      font-size: 13px;
      color: #d4d5dd;
      position: absolute;
      bottom: 0;
      background: #393c49;
      padding-bottom: 35px;

      .bet-warning-icon {
        width: 13px;
        height: 100%;
        float: left;
        margin: 1px 6px 1px 0;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        flex-basis: 6%;
        max-height: 19px;
      }

      .bet-login-warning {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-basis: 80%;
      }

      span.text-button {
        float: left;
        margin: 0 6px;
      }

      .text-button {
        font-weight: bold;
        cursor: pointer;
        color: #dcac0f;

        &:hover {
          color: white;
        }
      }
    }

    .place-bet-process {
      float: left;
      width: 100%;
      height: 28px;
      border-radius: 2px;
      background-color: transparent;
      position: absolute;
      top: 38%;

      .loader {
        width: 45px;
        height: 45px;
        position: absolute;
        top: 10px;
        left: 45%;
        border-radius: 50%;
        background: transparent;
        border-top: 3px solid #d2167e;
        border-right: 3px solid transparent;
        animation: 0.5s spin linear infinite;
      }
    }
  }

  .decimal-types-checkbox {
    .custom-select {
      margin-top: -3px;
      border-radius: 0 !important;;
      margin-bottom: -3px;

      select {
        cursor: pointer !important;
        border-radius: 0 !important;
        color: white;
      }
    }
  }

  .bet-slip-menu {
    display: flex;
    width: 100%;
    background-color: #272A33;
    border-top: 2px solid $asset1;
    cursor: pointer;

    .bet-slip-menu-tab {
      width: 50%;
      height: 35px;
      line-height: 38px;
      text-align: left;
      padding-left: 10px;
      font-size: 11px;
      letter-spacing: 0.5px;
      font-weight: bold;
      font-family: "Roboto", "Noto Sans Armenian", sans-serif;
    }

    .active-tab {
      background-color: #333540;
    }

    .calculator-icon {
      width: 16px;
      height: 16px;
      float: right;
      margin: 10px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .bet-slip-container {
    margin-top: 1px;

    .superbet-block, .offer-bet-block {
      display: flex;
      width: 100%;
      height: 40px;
      background-color: $valhalla;
      border-bottom: 1px solid #242630;

      p {
        cursor: default;
        font-size: 13px;
        line-height: 30px;
        display: flex;
        padding-left: 7px;
        margin-top: 5px;
      }
    }

    .offer-bet-block {
      color: $alpine;
      cursor: default;

      .new-icon {
        height: 15px;
        color: white;
        font-size: 10px;
        line-height: 15px;
        font-weight: bold;
        margin-top: 11px;
        margin-left: 6px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 2px;
        border-radius: 2px;
        background-color: $alpine;
      }
    }

    .offer-bet-content {
      display: flex;
      width: 100%;
      background-image: linear-gradient(to right top, #1a5c7b, #225179, #314472, #403667, #4c2657);
    }

    .super-bet-content {
      display: flex;
      width: 100%;
      background-image: linear-gradient(to right top, #228e69, #007a74, #006574, #004f68, #1e3953);
    }

    .offer-bet-content, .super-bet-content {
      p {
        width: 40%;
        letter-spacing: 0.5px;
        text-align: left;
        padding-left: 10px;
        cursor: default;
      }
    }

    .bet-slip-icon {
      max-width: 22%;
      position: absolute;
      right: 25px;
      margin-top: 10px;
    }

    .quick-bet-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #212531;

      .quick-bet-info {
        width: 100%;
        background-color: #212531;
        color: #aeb1b5;
        text-align: left;
        font-size: 10.5px;
        padding: 5px;
        cursor: default;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0;
      }
    }
  }

  .open-bets-container {
    height: 82vh;
    padding: 3px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;

    .cash-out-block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96%;
      height: 28px;

      .cash-out-button {
        display: flex;
        width: 100%;
        height: inherit;
        justify-content: space-between;
        padding-left: 8px;
        padding-right: 23px;
        cursor: pointer;
        margin-top: 2px;
        margin-bottom: 2px;
        background-color: #1e8559;

        p {
          font-size: 11px;
          line-height: 5px;
          color: white;
        }

        &:hover {
          background-color: #208c5e;
        }

        &:active {
          background-color: #1c8057;
        }
      }
    }

    span {
      text-align: center;
      display: block;
      margin: 12px 6px
    }

    span.open-close-arrow {
      margin: 0 10px 0;
    }

    .my-bets-bet-item {
      display: flex;
      min-height: 70px;
      border-radius: 3px;
      border: 1px solid #6a707b;
      background-color: #4c515a;
      margin-bottom: 5px;
      flex-direction: column;

      .mb-item-header {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 5px;
        margin-top: 5px;
        width: 100%;
        height: 40px;

        .mb-time {
          height: 17px;
          margin: 0;
          font-size: 11px;
          color: #afb8c9;
        }

        .mb-stadium-name {
          height: 20px;
          margin: 0;
          font-size: 12px;
        }

        .clock-icon {
          position: absolute;
          right: 12px;
          top: 2px;
        }
      }

      .mb-type {
        height: 20px;
        font-size: 12px;
        color: #afb8c9;
        text-align: left;
        margin-top: 5px;
        padding-left: 5px;
      }

      .mb-item-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 5px;
        width: 95%;
        height: 40px;

        .mb-player-name {
          height: 20px;
          font-size: 13px;
          color: $alpine;
          font-weight: bold;
        }

        .mb-coef {
          font-weight: bold;
          height: 20px;
          padding-right: 2px;
          font-size: 12px;
          color: $alpine;
        }
      }
    }

    .history-bet-item {
      display: flex;
      margin-top: 10px;
      height: auto;
      min-height: auto;
      width: 95%;
      border-radius: 5px;
      background-color: $themeColor4;
      position: relative;

      &.opened {
        height: auto;
      }

      &:before {
        content: "▼";
        position: absolute;
        font-size: 12px;
        background: unset !important;
        color: #e6e7f5;
        pointer-events: none;
        padding: 11px 0 0;
        right: 46vw;
        line-height: 15px
      }

      &.opened:before {
        content: "▼";
        position: absolute;
        font-size: 12px;
        background: unset !important;
        color: #e6e7f5;
        pointer-events: none;
        padding: 3px 0;
        right: 46vw;
        line-height: 30px;
        transform: rotate(180deg);
      }

      .data-id {
        .bet-item-more-info-status {
          color: $asset3;
          line-height: 20px;
          padding: 2px 4px;
          text-align: right;
        }
      }

    }

    .bet-info-item {
      width: 100%;
      line-height: 40px;
      height: auto;

      p {
        float: left;
        line-height: 2px;

        &.id {
          margin-left: 10px;
          margin-top: 0;
        }


      }

      .open-bet-title {
        display: flex;
        justify-content: space-between;
        height: 38px;
        align-items: center;
        background: $themeColor6;
        padding: 0 5px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;

        .clock-icon {
          position: absolute;
          right: 11px;
          top: 11px;
          width: 16px;
        }

        .bet-type-name, .date-and-time {
          font-size: 12px;
          color: white;
          padding-left: 8px;
        }
      }

      .date-and-time {
        padding: 0 10px;
        color: antiquewhite;
        margin-right: 22px;
      }

      .coef-value {
        line-height: 15px;
        right: 14px;
        color: $alpine;

        &.lost {
          color: #9e0202
        }
      }

      .bet-result-info {
        color: #ff2c2c;
        position: absolute;
        right: 25px;
        padding-top: 6px;

        &.win {
          color: #8fd414;
        }

        &.lost {
          color: #ff2c2c;
        }

        &.return, &.accepted {
          color: #dddddd;
        }

        &.cashouted {
          color: #ffdb59;
        }
      }

      .odd-info {
        padding-bottom: 5px;

        p {
          margin-left: 10px;
          margin-top: 7px;
          color: #9295a1;

          &.win {
            color: #8fd414;
          }
        }
      }

      .game-info {
        display: flex;
        flex-direction: column;
        border-top: thin solid #2F323E;
        border-bottom: thin solid #2F323E;
        margin-bottom: 4px;
        padding: 4px 0 4px 4px;
        width: inherit;

        .betItem {
          color: #9295a1;
          line-height: 4px;

          &.title {
            color: #fdfdfd;
            font-weight: bold;
            font-size: 12px;
          }
        }

        .betItem-name {
          color: $alpine;
        }

        .betItem-multi {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 98%
        }
      }

      .win-and-coef-info, .odd-info {
        width: 95%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        line-height: 20px;
        align-items: center;

        p {
          margin-left: 2px;
          margin-top: 15px;
          color: #9295a1;
        }
      }

      .ogg-info {
        width: 96%;
        border-top: 2px solid #313643;
      }
    }
  }

  .bet-history-container {
    cursor: default;
    margin-bottom: 5px;
    background-color: #333540;

    .history-is-empty {
      width: 100%;
      font-size: 13px;
      line-height: 40px;

      b {
        color: $alpine;
        cursor: pointer;

        &:hover {
          color: #e9b95f;
        }
      }
    }
  }

  .bet-hint {
    display: block;
    width: 100%;
    height: 22px;
    line-height: 22px;
    font-size: 11px;
    text-align: left;
    cursor: default;
    color: #aeb1b5;
    padding-left: 5px;
    background-color: #212531;
  }

  .warning-bets-count {
    min-height: 90px;
    padding: 3px 0;

    span {
      text-align: center;
      display: block;
      margin: 12px 6px
    }
  }

  .place-bet-results {
    float: left;
    width: 94%;
    height: 27px;
    border-radius: 4px;
    margin-left: 3%;
    margin-top: 2px;
    background-color: #696969;
    position: absolute;
    font-size: 12px;
    line-height: 25px;
    color: #ffffff;
    text-align: center;
    top: 77%;

    &.accepted {
      top: 105px;
      background-color: #1a984c;
    }
  }
}