.tennis-instant-game {
  justify-content: center;
  display: flex;
  width: 86%;
  height: 40%;
  background: rgba(0, 0, 0, 0.44);
  box-shadow: 0px -1px 11px 2px rgba(0, 0, 0, 0.44);
  align-self: baseline;
  margin: 5% auto;

  .slick-slider {
    width: 100%;
  }

  .slick-list {
    margin: 18% auto;
  }

}

.tennis-instant-game .button {
  min-width: 32vw;
  height: 8.8vw;
  line-height: 8.8vw;
  font-size: 4.3vw;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  background: rgba(119, 119, 119, 0.7);
  border: 3px solid rgba(255, 255, 255, 0);
  text-shadow: 0 1px 4px #1d1d1d;
  -webkit-user-select: none;
  user-select: none;
  color: white;
  box-shadow: 0 0 10px 0 #3e3e3e inset;
  transition: all .15s ease-in-out;
}