.app-loader {
  width: 55px;
  height: 55px;
  display: block;
  position: absolute;
  top: 25%;
  left: 45%;
  border-radius: 50%;
  border-top: 4px solid #d2167e;
  border-right: 4px solid transparent;
  background: transparent;
  -webkit-animation: 0.7s spin linear infinite;
  animation: 0.7s spin linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.own-console-block {
  position: absolute;
  display: flex;
  padding: 12px;
  flex-wrap: wrap;
  top: 15%;
  min-height: 120px;
  width: 100%;
  background: rgba(21, 20, 20, 0.12);
  z-index: 100;
  font-size: 16px;
}

.grecaptcha-badge {
  display: none !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
}

input {
  -webkit-border-radius:0;
  border-radius:0;
}

*:focus {
  outline: none;
}
